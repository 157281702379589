import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09e9b903"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "mr-6"
}
const _hoisted_3 = {
  key: 1,
  class: "mr-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tags_input = _resolveComponent("vue-tags-input")!
  const _component_vxe_input = _resolveComponent("vxe-input")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_vxe_pulldown = _resolveComponent("vxe-pulldown")!

  return (_openBlock(), _createBlock(_component_vxe_pulldown, {
    class: "w-full",
    ref: (el) => (_ctx.instance = el),
    transfer: _ctx.transfer,
    onHidePanel: _ctx.onHidePanel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.multiselect)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_vue_tags_input, {
                class: "rounded-r-none border-r-0",
                ref: (el) => (_ctx.tagsInput = el),
                placeholder: _ctx.placeholder,
                disabled: _ctx.readonly,
                readonly: !_ctx.addIfNotExists,
                "add-on-blur": false,
                "delete-on-backspace": false,
                tags: _ctx.selectedRows,
                "is-duplicate": 
              (tags, tag) => tags.some((e) => e.value === tag.value)
            ,
                modelValue: _ctx.tag,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tag) = $event)),
                onFocus: _ctx.onFocus,
                onBeforeDeletingTag: _ctx.onBeforeDeletingTag,
                onBeforeAddingTag: _ctx.onBeforeAddingTag
              }, null, 8, ["placeholder", "disabled", "readonly", "tags", "is-duplicate", "modelValue", "onFocus", "onBeforeDeletingTag", "onBeforeAddingTag"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.showSearch)
                ? (_openBlock(), _createBlock(_component_vxe_input, {
                    key: 0,
                    class: "w-full rounded-r-none border-r-0",
                    clearable: !_ctx.readonly,
                    placeholder: _ctx.placeholder,
                    readonly: _ctx.readonly,
                    modelValue: _ctx.keyword,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.keyword) = $event)),
                    modelModifiers: { trim: true },
                    onChange: _ctx.onKeywordChange,
                    onFocus: _ctx.onFocus,
                    onClear: _ctx.onClear
                  }, null, 8, ["clearable", "placeholder", "readonly", "modelValue", "onChange", "onFocus", "onClear"]))
                : (_openBlock(), _createBlock(_component_vxe_input, {
                    key: 1,
                    class: "w-full rounded-r-none border-r-0",
                    clearable: !_ctx.readonly,
                    placeholder: _ctx.placeholder,
                    readonly: true,
                    modelValue: _ctx.text,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.text) = $event)),
                    onFocus: _ctx.onFocus,
                    onClear: _ctx.onClear
                  }, null, 8, ["clearable", "placeholder", "modelValue", "onFocus", "onClear"]))
            ])),
        _createElementVNode("div", {
          class: "absolute top-0 right-0 rounded-r w-6 h-full flex items-center justify-center bg-white border",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.togglePanel && _ctx.togglePanel(...args)), ["stop"]))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(
              _ctx.instance.isPanelVisible && _ctx.instance.isPanelVisible()
                ? 'vxe-icon-caret-up'
                : 'vxe-icon-caret-down'
            )
          }, null, 2)
        ])
      ])
    ]),
    dropdown: _withCtx(() => [
      _createElementVNode("div", {
        class: "border rounded bg-gray-300",
        style: _normalizeStyle({
          width:
            typeof _ctx.dropdownWidth !== 'string'
              ? `${_ctx.dropdownWidth}px`
              : _ctx.dropdownWidth,
          height:
            typeof _ctx.dropdownHeight !== 'string'
              ? `${_ctx.dropdownHeight}px`
              : _ctx.dropdownHeight,
        })
      }, [
        _createVNode(_component_Grid, _mergeProps({
          ref: "grid",
          class: "border rounded"
        }, _ctx.gridOptions, {
          rowClassName: 
            ({ row }) => {
              return _ctx.disallowSelectedRowIds.some((id) => id === row[_ctx.rowId])
                ? 'bg-opacity-30 opacity-30'
                : undefined;
            }
          ,
          onCurrentRowChanged: _ctx.onSelectedRowChanged,
          onCheckboxChange: _ctx.onCheckboxChange,
          onCheckboxAll: _ctx.onCheckboxAll,
          onPageChanged: _ctx.onPageChanged
        }), {
          form: _withCtx(() => [
            (_ctx.multiselect && _ctx.showSearch)
              ? (_openBlock(), _createBlock(_component_vxe_input, {
                  key: 0,
                  class: "w-full",
                  type: "search",
                  clearable: true,
                  placeholder: "請輸入關鍵字",
                  modelValue: _ctx.grid.keyword,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.grid.keyword) = $event)),
                  modelModifiers: { trim: true },
                  onChange: _ctx.onKeywordChange
                }, null, 8, ["modelValue", "onChange"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 16, ["rowClassName", "onCurrentRowChanged", "onCheckboxChange", "onCheckboxAll", "onPageChanged"])
      ], 4)
    ]),
    _: 1
  }, 8, ["transfer", "onHidePanel"]))
}